import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { IconFaceSmile, IconPlusCircle } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/icon",
  "title": "Icon",
  "navTitle": "Icon"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <IconFaceSmile mdxType="IconFaceSmile" />
    </PlaygroundPreview>
    <p>{`See the `}<InternalLink href="/foundation/visual-assets/icons" mdxType="InternalLink">{`the icon library`}</InternalLink>{` to browse a full list of HDS icons.`}</p>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`An icon should always serve a distinct, easily understandable purpose.`}</strong>{` Icons should not be used solely for decoration.`}</li>
      <li parentName="ul"><strong parentName="li">{`An icon should preferably be accompanied with a text label to give context for interpretation.`}</strong>{` The meaning of icons by them self can be ambiguous and change depending on the context. Label can be omitted only if the meaning of the icon is commonly established, or can be easily interpreted from the context, e.g. volume buttons in video playback.`}</li>
      <li parentName="ul"><strong parentName="li">{`An icon and its related content or label should have the same meaning.`}</strong>{` Conflicting and ambivalent interpretations can have a negative effect on usability and accessibility.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <IconFaceSmile aria-hidden="true" mdxType="IconFaceSmile" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "icon-sizes",
      "style": {
        "position": "relative"
      }
    }}>{`Icon sizes`}<a parentName="h4" {...{
        "href": "#icon-sizes",
        "aria-label": "icon sizes permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <IconFaceSmile size="xs" aria-hidden="true" mdxType="IconFaceSmile" />
  <IconFaceSmile size="s" aria-hidden="true" mdxType="IconFaceSmile" />
  <IconFaceSmile size="m" aria-hidden="true" mdxType="IconFaceSmile" />
  <IconFaceSmile size="l" aria-hidden="true" mdxType="IconFaceSmile" />
  <IconFaceSmile size="xl" aria-hidden="true" mdxType="IconFaceSmile" />
    </PlaygroundPreview>
    <h3 {...{
      "id": "icon-visible-for-assistive-technologies",
      "style": {
        "position": "relative"
      }
    }}>{`Icon visible for assistive technologies`}<a parentName="h3" {...{
        "href": "#icon-visible-for-assistive-technologies",
        "aria-label": "icon visible for assistive technologies permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`If the icon has a meaning or is used as an interactive element, it should not be hidden from assistive technologies. This is important if, for example, you are using icons as buttons.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <IconPlusCircle aria-label="Add an item" mdxType="IconPlusCircle" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      